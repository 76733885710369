import clsx from 'clsx';
import { FC, useState } from 'react';
import { IWithClassName } from '~/models/dev';
import { Svg } from '~/shared/svg';
import styles from './error-box.module.scss';

export type ErrorBoxType = 'error' | 'warning';

interface IErrorBox extends IWithClassName {
    children: React.ReactNode | string;
    canDismiss?: boolean;
    type?: ErrorBoxType;
}

const ErrorBox: FC<IErrorBox> = ({ children, canDismiss, className, type = 'error' }) => {
    const [isDismissed, setIsDismissed] = useState(false);

    if (isDismissed) {
        return null;
    }

    return (
        <div
            className={clsx(styles.errorBox, className, {
                [styles.error]: type === 'error',
                [styles.warning]: type === 'warning',
            })}
        >
            <Svg thick name="icon-badge-warning" className={styles.warningIcon} />

            <div className={styles.text}>{children}</div>

            {canDismiss && (
                <button aria-label="Close" className={styles.dismissButton} type="button" onClick={() => setIsDismissed(true)}>
                    <Svg name="icon-close" className={styles.dismissIcon} />
                </button>
            )}
        </div>
    );
};

export default ErrorBox;
