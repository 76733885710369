import clsx from 'clsx';
import React, { Ref, FC, forwardRef } from 'react';
import Loader from '~/shared/loader/loader.component';
import { Svg } from '~/shared/svg';
import ButtonWrapper from '../button-wrapper.component';
import Tooltip, { TooltipContentWrapper } from '~/shared/tooltip/tooltip.component';

import styles from './button-circle.module.scss';
import { IButtonCommonProps } from '../buttons.models';
import Text from '~/shared/text/text.component';
import IconBadge from '~/shared/icon-badge/icon-badge.component';

export interface IButtonCircleProps extends IButtonCommonProps {
    iconName?: string;
    iconText?: string;
    iconClassName?: string;
    text?: string;
    textPosition?: 'right' | 'bottom';
    buttonSize?: 'default' | 'small';
    buttonStyle?: 'light' | 'default' | 'dark';
    badge?: string | number;
    thickIcon?: boolean;
    fillIcon?: boolean;
    tooltipText?: string;
    tooltipClassName?: string;
    circleClassName?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ButtonCircle: FC<IButtonCircleProps> = forwardRef(
    (
        {
            className,
            fetching,
            iconName,
            iconClassName,
            iconText,
            thickIcon,
            text,
            textPosition = 'right',
            buttonSize = 'default',
            buttonStyle = 'default',
            fillIcon,
            disabled,
            badge,
            tooltipText,
            tooltipClassName,
            circleClassName,
            ...restProps
        },
        ref?: Ref<HTMLButtonElement> | Ref<HTMLAnchorElement>
    ): JSX.Element => {
        const buttonClassNames = clsx(
            className,
            styles.root,
            styles[`${buttonStyle}Style`],
            styles[`${textPosition}Text`],
            styles[`${textPosition}`],
            {
                [styles.fetching]: fetching,
                [styles.fillIcon]: fillIcon,
            }
        );

        const getIcon = () => {
            if (iconName) {
                return <Svg name={iconName} thick={thickIcon} className={clsx(iconClassName, styles.icon)} />;
            }
            if (iconText) return <Text>{iconText}</Text>;
            return null;
        };

        const ButtonContent = () => (
            <ButtonWrapper className={buttonClassNames} ref={ref} disabled={disabled} fetching={fetching} {...restProps}>
                <span className={clsx(styles[`${buttonSize}Size`], circleClassName)}>
                    <IconBadge className={styles.badge} disabled={disabled} value={badge as string} />
                    {getIcon()}
                    {fetching && (
                        <span className={styles.loaderWrapper}>
                            <Loader className={styles.loader} padding="0" size="22px" borderWidth="2px" />
                        </span>
                    )}
                </span>
                {text && (
                    <Text color="primaryColor" className={styles.text}>
                        {text}
                    </Text>
                )}
            </ButtonWrapper>
        );

        return tooltipText ? (
            <Tooltip className={tooltipClassName} content={<TooltipContentWrapper>{tooltipText}</TooltipContentWrapper>}>
                <ButtonContent />
            </Tooltip>
        ) : (
            <ButtonContent />
        );
    }
);

export default ButtonCircle;
