import clsx from 'clsx';
import { FC, Ref, forwardRef } from 'react';
import Loader from '~/shared/loader/loader.component';
import ButtonWrapper from '../button-wrapper.component';
import { IButtonCommonProps } from '../buttons.models';
import styles from './button.module.scss';

export interface IButtonProps extends IButtonCommonProps {
    icon?: JSX.Element | null | undefined;
    buttonStyle?: 'primary' | 'secondary' | 'secondaryLight' | 'tertiary' | 'clean';
    buttonSize?: 'default' | 'small' | 'link';
    fullWidth?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Button: FC<IButtonProps> = forwardRef(
    (
        { children, buttonStyle = 'primary', buttonSize = 'default', className, icon, fetching, fullWidth, ...restProps }: IButtonProps,
        ref?: Ref<HTMLButtonElement> | Ref<HTMLAnchorElement>,
    ): JSX.Element => {
        const buttonClassNames = clsx(className, styles[`${buttonSize}Size`], styles[`${buttonStyle}Style`], {
            [styles.fetching]: fetching,
            [styles.iconOnly]: icon && !children,
            [styles.fullWidth]: fullWidth,
        });

        return (
            <ButtonWrapper className={buttonClassNames} fetching={fetching} ref={ref} {...restProps}>
                {icon || null}
                {children && <span className={styles.content}>{children}</span>}
                {fetching && (
                    <span className={styles.loaderWrapper}>
                        <Loader className={styles.loader} padding="0" size="22px" borderWidth="2px" />
                    </span>
                )}
            </ButtonWrapper>
        );
    },
);

export default Button;
