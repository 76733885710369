import clsx from 'clsx';
import React, { Ref, FC, forwardRef } from 'react';
import { Link } from '~/shared/link';

import { IButtonCommonProps } from './buttons.models';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ButtonWrapper: FC<IButtonCommonProps> = forwardRef(
    (
        { className, fetching, disabled, href, shouldRenderStaticLink, target, children, download, isSpan, ...restProps },
        ref?: Ref<HTMLButtonElement> | Ref<HTMLAnchorElement>
    ): JSX.Element => {
        if (isSpan) {
            return (
                <span className={clsx(className)} {...restProps} ref={ref as Ref<HTMLButtonElement>}>
                    {children}
                </span>
            );
        }

        if (href && !disabled) {
            return (
                <Link
                    className={clsx(className)}
                    target={target}
                    ref={ref as Ref<HTMLAnchorElement>}
                    href={href}
                    shouldRenderStaticLink={shouldRenderStaticLink}
                    style={restProps?.style}
                    download={download}
                    onClick={restProps?.onClick as unknown as any} // TODO: fix this type
                    {...(target === '_blank'
                        ? {
                              rel: 'noopener noreferrer',
                          }
                        : null)}
                >
                    {children}
                </Link>
            );
        }

        return (
            <button className={clsx(className)} disabled={fetching || disabled} type="button" {...restProps} ref={ref as Ref<HTMLButtonElement>}>
                {children}
            </button>
        );
    }
);

export default ButtonWrapper;
