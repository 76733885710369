import React, { FC } from 'react';
import MaxLengthCounter from '../max-length-counter/max-length-counter.component';
import ValidationErrorMessage from '../validation-error-message/validation-error-message.component';
import ValidationValidMessage from '../validation-valid-message/validation-valid-message.component';
import styles from './input-validation.module.scss';

interface IProps {
    validMessage?: string | JSX.Element;
    errorMessage?: string | JSX.Element;
    maxLength?: number;
    value?: string;
}

const InputValidation: FC<IProps> = ({ errorMessage, maxLength, value, validMessage }) => {
    if (!errorMessage && !maxLength && !validMessage) return null;
    return (
        <div className={styles.root}>
            {errorMessage && <ValidationErrorMessage>{errorMessage}</ValidationErrorMessage>}
            {validMessage && !errorMessage && <ValidationValidMessage>{validMessage}</ValidationValidMessage>}
            {maxLength && <MaxLengthCounter className={styles.maxLength} maxLength={maxLength} value={value?.toString() || ''} />}
        </div>
    );
};

export default InputValidation;
