import React, { useState, InputHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';
import { IFormElementCommonProps } from '../common/common';
import styles from './input.module.scss';
import Label from '../common/label/label.component';
import { Svg } from '~/shared/svg';
import ErrorInputIcon from '~/shared/form-elements/common/error-input-icon/error-input-icon.component';
import InputValidation from '~/shared/form-elements/common/input-validation/input-validation.component';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormElementCommonProps {
    autocomplete?: 'off' | 'on';
    iconType?: string;
}

const Input = forwardRef<HTMLInputElement, IProps>((props, ref): JSX.Element => {
    const {
        type = 'text',
        value,
        maxLength,
        label = '',
        name = '',
        required = false,
        className = '',
        wrapperClassName = '',
        isValid = true,
        errorMessage,
        disabled,
        readOnly,
        hideLabel,
        validMessage,
        iconType,
        ...rest
    } = props;

    const [passIsShown, setPassIsShown] = useState(false);

    let passAwareType = '';
    if (type === 'password') {
        passAwareType = passIsShown ? 'text' : 'password';
    } else {
        passAwareType = type;
    }

    const isError = !isValid || errorMessage;

    return (
        <div
            className={clsx(wrapperClassName, {
                [styles.readOnly]: readOnly,
                [styles.hidden]: props.hidden,
            })}
        >
            {label && (
                <Label name={`Input${name}`} disabled={disabled} isHidden={hideLabel} required={required}>
                    {label}
                </Label>
            )}
            <div className={styles.inputWrapper}>
                <input
                    readOnly={readOnly}
                    ref={ref}
                    name={name}
                    id={`Input${name}`}
                    disabled={disabled}
                    className={clsx(className, styles.input, {
                        [styles.invalid]: isError,
                        [styles.passInput]: type === 'password',
                        [styles.searchInput]: type === 'search',
                        [styles.readOnlyInput]: readOnly,
                    })}
                    type={passAwareType}
                    maxLength={maxLength}
                    value={value}
                    required={required}
                    {...rest}
                />
                {isError && <ErrorInputIcon />}
                {type === 'password' && !isError && (
                    <button type="button" onClick={() => setPassIsShown(!passIsShown)} className={styles.showPassBtn}>
                        {' '}
                        {passIsShown ? 'Hide' : 'Show'}
                    </button>
                )}
                {type === 'search' && <Svg name="search" thick className={styles.icon} />}

                {iconType && <Svg name={iconType} className={styles.genericIcon} />}
            </div>
            <InputValidation errorMessage={errorMessage} value={value?.toString()} maxLength={maxLength} validMessage={validMessage} />
        </div>
    );
});

export default Input;
