import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './validation-valid-message.module.scss';
import Text from '~/shared/text/text.component';
import { IWithClassName } from '~/models/dev';
import { Svg } from '~/shared/svg';

const ValidationValidMessage: FC<IWithClassName> = ({ className, children }) => (
    <div className={clsx(styles.root, className)}>
        <Text textStyle="bodySmall">{children}</Text>
        <Svg name="check" className={styles.icon} thick />
    </div>
);

export default ValidationValidMessage;
