import React, { memo, FC, AnchorHTMLAttributes, Ref } from 'react';
import RouterLink, { LinkProps } from 'next/link';

import clsx from 'clsx';
import { IWithClassName } from '~/models/dev';
import { isExternalLink, isMailLink, isTelLink } from '~/helpers';
import { Bff } from '~/models/bff.d';

import styles from './link.module.scss';
import { GA4CampaignClicked, GA4Download, GA4EmailLInk, GA4ExternalLink, GA4PhoneLink } from '~/libs/ga4';

type ILinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
    Omit<LinkProps, 'href' | 'as'> & {
        shouldRenderStaticLink?: boolean;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
        onMouseEnter?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
        ref?: Ref<HTMLAnchorElement>;
        // Used for tracking position of link on frontpage
        campaignSpot?: string;
        campaignType?: string;
        campaignId?: string;
    };

/**
 * <Link href="/internal/page">Internal link</Link>
 * <Link href="https://www.external.com/page">External link</Link>
 * <Link href="mailto:sbf@impact.dk">Mail link</Link>
 */
export const Link: FC<ILinkProps> = memo((props) => {
    const {
        passHref,
        prefetch,
        replace,
        scroll,
        shallow,
        href,
        className,
        shouldRenderStaticLink: isNormalLink,
        campaignType,
        campaignId,
        campaignSpot,
        ...anchorProps
    } = props;

    const { children, target, onClick, onMouseEnter } = anchorProps;

    const shouldRenderStaticLink =
        isNormalLink || isExternalLink(href as string) || isMailLink(href as string) || isTelLink(href as string) || target === '_blank' || !href;

    if (href === undefined || !href) {
        return (
            // eslint-disable-next-line
            <span className={className} onClick={onClick} onMouseEnter={onMouseEnter} {...(onClick || onMouseEnter ? { role: 'button' } : null)}>
                {children}
            </span>
        );
    }

    return shouldRenderStaticLink ? (
        <a
            href={href}
            className={className}
            {...(target === '_blank' ? { rel: 'noopener noreferrer' } : null)}
            {...anchorProps}
            onClick={(event) => {
                if (anchorProps.download && href) {
                    // Track download links
                    GA4Download();
                } else if (isExternalLink(href)) {
                    // track external link
                    GA4ExternalLink(href);
                } else if (isMailLink(href)) {
                    // Track email links
                    GA4EmailLInk();
                } else if (isTelLink(href)) {
                    // Track phone links
                    GA4PhoneLink();
                }

                // Trigger any previous onClick event
                if (typeof onClick === 'function') {
                    onClick(event);
                }
            }}
        >
            {children}
        </a>
    ) : (
        <RouterLink href={href} passHref={passHref} prefetch={prefetch} replace={replace} scroll={scroll} shallow={shallow}>
            <a
                {...anchorProps}
                onClick={(e) => {
                    if (campaignSpot) {
                        GA4CampaignClicked({
                            to: href,
                            campaignType,
                            campaignId,
                            campaignSpot,
                        });
                    }

                    if (typeof anchorProps.onClick === 'function') {
                        anchorProps.onClick(e);
                    }
                }}
                className={className}
            >
                {children}
            </a>
        </RouterLink>
    );
});

interface ICMSLinkProps extends IWithClassName, React.HTMLAttributes<HTMLAnchorElement> {
    link?: Bff.ILink;
    onClick?: () => void;
    prefetch?: boolean;
}

export const CMSLink: FC<ICMSLinkProps> = ({ link, className, prefetch, onClick, children }): JSX.Element => {
    const { openInNewWindow, title, url } = link ?? {};

    const linkProps = {
        className,
        prefetch,
        onClick,
        target: openInNewWindow ? '_blank' : undefined,
        href: url,
    };

    return (
        <Link {...linkProps}>
            <span className={clsx(openInNewWindow && styles.external)}>{children || title}</span>
        </Link>
    );
};
