import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './label.module.scss';
import { IWithClassName } from '~/models/dev';
import Text from '~/shared/text/text.component';

interface ILabelProps extends IWithClassName {
    name?: string;
    required?: boolean;
    disabled?: boolean;
    isHidden?: boolean;
}

const Label: FC<ILabelProps> = ({ name, required, disabled, className, children, isHidden }): JSX.Element => (
    <label
        htmlFor={name}
        className={clsx(className, styles.root, {
            [styles.disabled]: disabled,
            [styles.isHidden]: isHidden,
        })}
    >
        <Text textStyle="monoSmall">
            {children} {required && <span className={styles.requiredStar}>*</span>}
        </Text>
    </label>
);

export default Label;
