import React, { FC } from 'react';
import { IWithClassName } from '~/models/dev';
import Text from '~/shared/text/text.component';

interface IProps extends IWithClassName {
    maxLength: number;
    value: string;
}

const MaxLengthCounter: FC<IProps> = ({ maxLength, value, className }): JSX.Element => (
    <Text className={className} textStyle="monoSmall" secondary>
        {value.length} / {maxLength}
    </Text>
);

export default MaxLengthCounter;
