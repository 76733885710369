import React, { FC } from 'react';
import { Svg } from '~/shared/svg';
import styles from './error-input-icon.module.scss';

const ErrorInputIcon: FC = () => (
    <span className={styles.root}>
        <Svg name="icon-badge-warning" thick className={styles.icon} />
    </span>
);

export default ErrorInputIcon;
