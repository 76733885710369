import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { IWithClassName } from '~/models/dev';
import Text from '~/shared/text/text.component';
import styles from './validation-error-message.module.scss';

const ValidationErrorMessage: FunctionComponent<IWithClassName> = ({ children, className }) => (
    <Text textStyle="bodySmall" className={clsx(styles.errorMessage, className)}>
        {children}
    </Text>
);

export default ValidationErrorMessage;
