import clsx from 'clsx';
import React, { FC } from 'react';
import { IWithClassName } from '~/models/dev';

import styles from './icon-badge.module.scss';

interface IProps extends IWithClassName {
    value: string | number;
    disabled?: boolean;
}

const IconBadge: FC<IProps> = ({ className, value, disabled }) => {
    if (!value) return null;
    return <span className={clsx(disabled ? styles.rootDisabled : styles.rootDefault, className)}>{value}</span>;
};

export default IconBadge;
