import React, { FC } from 'react';
import { usePopperTooltip, Config as IPopperConfig } from 'react-popper-tooltip';
import clsx from 'clsx';
import styles from './tooltip.module.scss';
import 'react-popper-tooltip/dist/styles.css';
import { IWithClassName } from '~/models/dev';
import { Svg } from '~/shared/svg';

export const TooltipContentWrapper: FC<IWithClassName> = ({ className, children }) => (
    <span className={clsx(styles.contentWrapper, className)}>{children}</span>
);

interface IProps extends IPopperConfig, IWithClassName {
    content: JSX.Element | string;
}

const Tooltip: FC<IProps> = ({ content, children, className, placement = 'top', ...rest }) => {
    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({ placement, ...rest });

    return (
        <>
            <span ref={setTriggerRef} className={className}>
                {children || <Svg className={styles.fallbackIcon} name="info" />}
            </span>

            {visible && (
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: styles.content,
                    })}
                >
                    {content}
                    <div {...getArrowProps()} />
                </div>
            )}
        </>
    );
};

export default Tooltip;
