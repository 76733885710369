import React, { FC } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion, Orchestration } from 'framer-motion';
import Head from 'next/head';
import styles from './loader.module.scss';
import { IWithClassName } from '~/models/dev';

interface ILoaderProps extends IWithClassName {
    fixed?: boolean;
    size?: string;
    borderWidth?: string;
    color?: 'primary' | 'secondary' | 'grey';
    padding?: string;
    width?: string | null;
    children?: React.ReactNode;
    isVisible?: boolean;
    pageTitle?: string;
    delay?: Orchestration['delay'];
}

/**
 * { loading && <Loader  borderWidth=".3rem" size="70px" /> }
 * { loading && <Loader fixed delay={0.2} /> }
 * { loading && <Loader fixed>Loading text</Loader> }
 * { loading && <Loader isVisible={Boolean} /> }
 * { loading && <Loader pageTitle="FTZ" /> }
 */
const LoaderContent: FC<ILoaderProps> = (props) => {
    const { fixed = false, size = '50px', borderWidth = '5px', color = 'primary', padding = '10px', width = '100%', className, children } = props;

    const wrapperStyles = {
        padding,
        width,
    };

    const loaderStyles = {
        width: size,
        height: size,
        borderTopWidth: borderWidth,
    };

    const wrapperClassName = clsx(
        {
            [styles.wrapper]: !fixed,
            [styles.wrapperFixed]: fixed,
        },
        className
    );

    return (
        <div className={wrapperClassName} style={wrapperStyles as React.CSSProperties}>
            <div className={clsx(styles.loader, styles[`${color}Color`])} style={loaderStyles} />

            {children && <div className={styles.text}>{children}</div>}
        </div>
    );
};

const Loader: FC<ILoaderProps> = ({ isVisible = true, children, pageTitle, delay, ...rest }) => (
    <>
        {pageTitle && (
            <Head>
                <title key="title">{pageTitle}</title>
            </Head>
        )}

        <AnimatePresence>
            {isVisible && (
                <motion.div
                    key="loader"
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            delay,
                        },
                    }}
                    exit={{ opacity: 0 }}
                    transition={{
                        duration: 0.1,
                    }}
                >
                    <LoaderContent {...rest}>{children}</LoaderContent>
                </motion.div>
            )}
        </AnimatePresence>
    </>
);

export default React.memo(Loader);
